<script setup>
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { computed, onMounted, ref } from "vue";
import StaffRepository from "@/repositories/StaffRepository.js";
import { useToast } from "@/components/composables/notifications.js";

const { sendServerError } = useToast();

const table = ref(null);
const title = computed(() => {
  return "Estadísticas por oposición";
});

const fields = computed(() => {
  return [
    { label: "Oposición", key: "shortName" },
    { label: "N. Usuarios academia", key: "noAcademyUsers" },
    { label: "N. Usuarios de pago", key: "noPaidUsers" },
    { label: "N. Test realizados", key: "noAttempts" },
    { label: "N. Preguntas", key: "noQuestions" },
  ];
});

onMounted(async () => {
  try {
    await refreshData();
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
});

async function refreshData() {
  try {
    await table.value.refreshData(buildParams());
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
}

function buildParams() {
  return {};
}
</script>

<template>
  <div>
    <table-with-filters
      ref="table"
      :title="title"
      :fields="fields"
      :call="StaffRepository.publicExaminationStatisctsList"
      :skip-initial-refresh="true"
    >
    </table-with-filters>
  </div>
</template>
