<script setup>
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  percentage: {
    type: Number,
    required: true,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  isTitleBold: {
    type: Boolean,
    default: true,
  },
});
const roundedClass = computed(() => {
  if (props.isFirst) {
    return "rounded-t-lg";
  }
  if (props.isLast) {
    return "rounded-b-lg";
  }
  return "";
});
</script>

<template>
  <div class="border-b">
    <div class="flex items-center bg-background px-5 py-4 hover:bg-background-hover" :class="roundedClass">
      <div class="flex w-full">
        <div class="flex w-3/4 flex-col">
          <p class="text-lg text-secondary-800" :class="{ 'font-light': !isTitleBold, 'font-semibold': isTitleBold }">
            {{ title }}
          </p>
          <p
            class="mb-2 text-base font-light"
            :class="{ 'text-slate-600': !isTitleBold, 'text-secondary-800': isTitleBold }"
          >
            {{ percentage }}% preguntas falladas
          </p>
          <div class="mb-2 h-1.5 rounded-full bg-gray-400">
            <div class="h-1.5 rounded-full bg-red-600" :style="{ width: percentage + '%' }"></div>
          </div>
        </div>
        <slot name="actions"></slot>
      </div>
    </div>
    <hr class="mx-6 -mt-1 border-t-secondary-400/50" v-if="!isLast" />
  </div>
</template>
