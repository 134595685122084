<script setup>
import BaseIcon from "@/components/BaseIcon.vue";
import { ref } from "vue";
import MainRepository from "@/repositories/MainRepository.js";
import { useRouter } from "vue-router";
import BaseLoading from "@/components/BaseLoading.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import StatisticsItemSelector from "@/components/statistics/StatisticsItemSelector.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  correctAnswers: {
    type: Number,
    required: true,
  },
  incorrectAnswers: {
    type: Number,
    required: true,
  },
  blankAnswers: {
    type: Number,
    required: true,
  },
  publicExamination: {
    type: Number,
    required: true,
  },
  contentLevels: {
    type: Array,
    required: true,
  },
});

const router = useRouter();

const forceNew = true;
const type = "custom";
const dialogOpen = ref(false);
const isNewAttemptExecuting = ref(false);
const maxQuestions = 20;
const newAttemptDialog = () => {
  isNewAttemptExecuting.value = false;
  dialogOpen.value = true;
};

const data = {
  publicExamination: props.publicExamination,
  contentLevels: props.contentLevels,
  forceNew: forceNew,
  type: type,
  maxQuestions: maxQuestions,
};

async function newAttempt() {
  isNewAttemptExecuting.value = true;
  try {
    const newAttempt = MainRepository.newAttempt();
    const response = await newAttempt(data);
    move(response.data.uuid);
  } catch (error) {
    console.error("Error making the request:", error);
    isNewAttemptExecuting.value = true;
  } finally {
    dialogOpen.value = false;
  }
}

function move(uuid) {
  router.push({ name: "in-attempt", params: { uuid: uuid } });
}
</script>

<template>
  <statistics-item-selector
    :title="props.title"
    :correct-answers="props.correctAnswers"
    :incorrect-answers="props.incorrectAnswers"
    :blank-answers="props.blankAnswers"
  >
    <template #actions>
      <a @click="newAttemptDialog" class="ml-auto cursor-pointer">
        <base-icon icon-name="edit_content_level_statistics" title="test personalizado" />
      </a>
    </template>
    <template #description>
      <p class="mb-3 text-base font-light text-secondary-800">
        {{ description }}
      </p>
    </template>
  </statistics-item-selector>
  <base-dialog :is-open="dialogOpen">
    <template v-slot:header>Realizar test preguntas falladas {{ title }}</template>
    <template v-slot:default>
      <div class="mb-2 ml-1 mt-3">
        <p>
          Se creará un test personalizado de hasta {{ maxQuestions }} preguntas para practicar el {{ title }}.<br />
          ¿Está seguro de que desea empezar el test?
        </p>
      </div>
    </template>
    <template v-slot:buttons>
      <base-loading :invisible="!isNewAttemptExecuting" :size="6"></base-loading>
      <button-primary class="mr-2" @click="newAttempt" :disabled="isNewAttemptExecuting">
        <span v-if="!isNewAttemptExecuting">Empezar test</span>
        <span v-else>Creando test...</span>
      </button-primary>
      <button-secondary @click="dialogOpen = false" :disabled="isNewAttemptExecuting"> Cancelar</button-secondary>
    </template>
  </base-dialog>
</template>
