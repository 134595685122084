<script setup>
import BaseCard from "@/components/BaseCard.vue";
import { computed, onMounted, ref } from "vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useToast } from "@/components/composables/notifications.js";
import AttemptSelectorHeader from "@/components/attempt-selector/AttemptSelectorHeader.vue";
import ListSelector from "@/components/ListSelector.vue";
import {
  ATTEMPT_PRACTICAL_CASE,
  ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL,
  EXAM_CATEGORY_OPTIONS,
} from "@/components/utils.js";

const { items: exams, getList: getExams } = usePagination(MainRepository.contentExamList, 200, 0);

const { sendNotification, sendServerError } = useToast();

const selectedCategory = ref(null);

const props = defineProps({
  publicExamination: {
    type: Object,
    required: true,
  },
  selectedType: {
    type: String,
    required: true,
  },
  selectedExam: {
    type: [Object, null],
  },
});

const showableExams = computed(() => {
  if (exams.value != null && selectedCategory.value != null) {
    return exams.value.filter((item) => item.examCategory === selectedCategory.value);
  }
  return exams.value;
});

const showSelect = computed(() => {
  return props.selectedType === ATTEMPT_PRACTICAL_CASE || props.selectedType === ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL;
});

const emit = defineEmits(["unselect-public-examination", "unselect-type", "update-exam", "move"]);

onMounted(async () => {
  try {
    await getExams({
      public_examination__slug: props.publicExamination.slug,
      exam_type: props.selectedType,
    });
  } catch (error) {
    sendServerError(error);
  }
});

function unselectPublicExamination() {
  emit("unselect-public-examination");
}

function unselectType() {
  emit("unselect-type");
}

function selectExam(exam) {
  emit("update-exam", exam);
}

function move() {
  if (props.selectedExam != null) {
    emit("move");
  } else {
    sendNotification("warn", "info", "Por favor, seleccione un examen");
  }
}

function selectCategory(difficulty) {
  selectedCategory.value = difficulty;
}

function examTitle(element) {
  return element.title;
}
</script>

<template>
  <div>
    <attempt-selector-header
      :public-examination="publicExamination"
      :selected-exam="null"
      :selected-type="selectedType"
      :selected-difficulty="null"
      :questions="null"
      :selected-contents="null"
      @unselect-public-examination="unselectPublicExamination"
      @unselect-type="unselectType"
    />
    <base-card class="mt-4">
      <div v-if="showSelect">
        <h3 class="mb-3 ml-5 mt-3 text-xl">Elige la categoría</h3>
        <div class="grid grid-cols-3">
          <div
            v-for="(category, index) in EXAM_CATEGORY_OPTIONS"
            :key="index"
            class="hover:bg-background-hover' m-4 flex cursor-pointer flex-col items-center rounded rounded-lg bg-background px-5 py-6"
            :class="selectedCategory === category.value ? 'bg-primary-200' : ''"
            @click.prevent="selectCategory(category.value)"
          >
            <span class="mb-2 text-xl">{{ category.label }}</span>
          </div>
        </div>
      </div>

      <h3 class="mb-7 ml-5 mt-3 text-xl">Elige el examen</h3>

      <h4 v-if="showableExams && showableExams.length === 0" class="ml-5">
        No hay exámenes en la categoría seleccionada
      </h4>
      <list-selector
        :public-examination="publicExamination"
        :title-selector="(element) => examTitle(element)"
        :elements="showableExams"
        :model-value="selectedExam"
        class="mx-5"
        @update:modelValue="selectExam"
      />

      <div class="flex flex-col items-start">
        <button-primary class="mb-4 ml-3 mt-6" @click="move()">Continuar</button-primary>
      </div>
    </base-card>
  </div>
</template>
