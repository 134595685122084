import { useNotification } from "@kyvg/vue3-notification";
import * as Sentry from "@sentry/browser";
import { useAuthentication } from "@/components/composables/authentication.js";

const { notify } = useNotification();
const { redirectToLoginPage } = useAuthentication();

export function useToast() {
  const defaultDuration = 4000;
  const sendNotification = function (type, title, text) {
    notify({
      type: type,
      title: title,
      text: text,
      duration: defaultDuration,
    });
  };

  const sendSuccessNotification = function (title, text) {
    notify({
      type: "success",
      title: title,
      text: text,
      duration: defaultDuration,
    });
  };

  const sendWarnNotification = function (title, text = "", duration = null) {
    notify({
      type: "warn",
      title: title,
      text: text,
      duration: duration ?? defaultDuration,
    });
  };
  const sendError = function (text) {
    notify({
      title: "Ha ocurrido un error",
      text: text,
      duration: defaultDuration + 6000,
      type: "error",
    });
  };
  const sendServerError = function (exception = null, code = null) {
    if (exception != null) {
      if (exception.response?.data?.code === "not_authenticated") {
        redirectToLoginPage("Tu sesión ha caducado, por favor vuelve a introducir tus credenciales");
        return;
      }
      console.error(exception);
      Sentry.withScope(function (scope) {
        if (code !== null) {
          scope.setTag("wopo-error-code", code);
        }
        Sentry.captureException(exception);
      });
    }
    let text = "Vuelva a intentarlo de nuevo y si el error persiste, póngase en contacto con el administrador.";
    if (code != null) {
      text += "<br>Código: COD-" + code;
    }
    notify({
      title: "Ha ocurrido un error",
      text: text,
      duration: defaultDuration + 6000, // give user time to read the error
      type: "error",
    });
  };
  return { sendWarnNotification, sendSuccessNotification, sendNotification, sendError, sendServerError };
}
