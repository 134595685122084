<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import MainRepository from "@/repositories/MainRepository.js";
import { usePagination } from "@/components/composables/ws-pagination.js";
import BaseCard from "@/components/BaseCard.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { useRoute, useRouter } from "vue-router";
import UserInputSelector from "@/components/forms/UserInputSelector.vue";
import { DateTime } from "luxon";
import { formatDate } from "@/components/utils.js";
import StatisticsSelectorTotalLawList from "@/components/statistics/StatisticsSelectorTotalLawList.vue";

const { sendServerError } = useToast();

const router = useRouter();
const route = useRoute();

const { items: oppositions, getList: getOppositions } = usePagination(MainRepository.publicExaminationList, 100, 0);

const opposition = ref(null);
const oppositionStatistics = ref(null);
const statisticsTime = ref("7");

const endDate = computed(() => {
  const today = DateTime.now();
  return formatDate(today);
});

const startDate = computed(() => {
  let firstDate = DateTime.now();

  if (statisticsTime.value === "7") {
    firstDate = firstDate.minus({ days: 7 });
  } else if (statisticsTime.value === "30") {
    firstDate = firstDate.minus({ days: 30 });
  } else if (statisticsTime.value === "365") {
    firstDate = firstDate.minus({ days: 365 });
  }

  return formatDate(firstDate);
});

const filteredOppositions = computed(() => {
  return oppositions.value.filter((item) => item.userHasMembership === true);
});

const handleOppositionChange = async (selectedOpposition) => {
  try {
    await updateRoute(selectedOpposition.slug);
    await fetchOppositionStatistics();
  } catch (error) {
    sendServerError(error);
  }
};

const updateRoute = async (slug) => {
  await router.replace({ name: "public-examination-laws-statistics", params: { slug: slug } });
};

const statisticsTimeOptions = [
  { label: "Últimos 365 días", value: "365" },
  { label: "Últimos 30 días", value: "30" },
  { label: "Últimos 7 días", value: "7" },
];

const selectedOrder = ref("default");

onMounted(async () => {
  if (route.query.statisticsTime) {
    statisticsTime.value = route.query.statisticsTime;
  }
  try {
    await fetchData(route.params.slug);
    await fetchOppositionStatistics();
  } catch (error) {
    sendServerError(error);
  }
});

const user = inject("user");
const userIdForStatistics = ref(null);

const changeUser = (newUserId) => {
  userIdForStatistics.value = newUserId;
  refreshData();
};

const fetchData = async (slug) => {
  try {
    await getOppositions({
      slug: slug,
    });
    if (slug) {
      opposition.value = filteredOppositions.value.find((item) => item.slug === slug);
    }
    if (!opposition.value && filteredOppositions.value.length > 0) {
      opposition.value = oppositions.value[0];
    }
    await router.replace({ name: "public-examination-laws-statistics", params: { slug: opposition.value.slug } });
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
};

const refreshData = () => {
  fetchOppositionStatistics();
};

const fetchOppositionStatistics = async () => {
  const slug = route.params.slug;

  try {
    const response = await MainRepository.publicExaminationStatistics({
      slug,
      timeRange: statisticsTime.value,
      userId: userIdForStatistics.value,
    });
    oppositionStatistics.value = response.data;

    orderStatistics();
  } catch (error) {
    sendServerError(error, "FETCH-OPPOSITION-STATISTICS");
  }
};

const statisticsOrderOptions = [
  { label: "Ordenar por defecto", value: "default" },
  { label: "Ordenar por preguntas falladas (%)", value: "incorrect" },
  { label: "Ordenar por preguntas acertadas (%)", value: "correct" },
  { label: "Ordenar por preguntas en blanco (%)", value: "blank" },
];

const orderStatistics = () => {
  if (!oppositionStatistics.value) return;
  // incorrect case is handled by the server as this is the default order from backend
  if (selectedOrder.value === "blank") {
    oppositionStatistics.value.topFailLawList.sort((a, b) => {
      const blankPercentageA = 100 - a.incorrectPercentage - a.correctPercentage;
      const blankPercentageB = 100 - b.incorrectPercentage - b.correctPercentage;

      return blankPercentageB - blankPercentageA;
    });
  } else if (selectedOrder.value === "correct") {
    oppositionStatistics.value.topFailLawList.sort((a, b) => b.correctPercentage - a.correctPercentage);
  } else if (selectedOrder.value === "default") {
    // The list is ordered by the names of the topics, for this the ids are used right now,
    // check this if new topics are created that unorder the list
    oppositionStatistics.value.topFailLawList.sort((a, b) => a.lawId - b.lawId);
  }
};

const hasMostFailedLaws = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.topFailLawList.length > 0;
});
const mostFailedLaws = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.topFailLawList;
});
</script>

<template>
  <title-header-view>
    <div>
      <div class="flex justify-between">
        <h2 class="text-3xl font-semibold text-secondary-800">Estadísticas</h2>
      </div>

      <base-card class="mt-8 p-8">
        <div class="w-full">
          <base-label label="Oposición" for-label="select-opposition" />
          <base-complex-select
            v-model="opposition"
            id="select-opposition"
            name="select-opposition"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="filteredOppositions"
            @update:model-value="handleOppositionChange"
          >
          </base-complex-select>
        </div>
      </base-card>

      <base-card v-if="user !== null && user.isSuperuser" class="mt-8 bg-admin p-8">
        <div class="w-full">
          <base-label label="Cambiar usuario" for-label="user-id" />
          <user-input-selector id="user-id" @user-id="changeUser" />
        </div>
      </base-card>
      <base-card class="mt-8 p-8">
        <div class="flex">
          <div class="mb-5 mt-3 flex flex-col">
            <p v-if="opposition" class="text-xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
          </div>
          <div class="ml-auto flex items-center text-center">
            <div class="ml-3">
              <base-select
                v-model="selectedOrder"
                id="order-test-statistics"
                name="order-test-statistics"
                :options="statisticsOrderOptions"
                @change="refreshData"
              />
            </div>
            <p class="ml-2 text-xs font-medium text-secondary-400">Del {{ startDate }} al {{ endDate }}</p>
            <div class="ml-3">
              <base-select
                v-model="statisticsTime"
                id="select-statistics-time"
                name="select-statistics-time"
                :options="statisticsTimeOptions"
                @change="refreshData"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">Resultados de test realizados por ley</p>
            <p class="text-md font-light text-secondary-800">
              Estos porcentajes corresponden solo a los test personalizados por ley, tema o parte general/procesal
            </p>
          </div>
          <div class="flex flex-row">
            <p v-if="!hasMostFailedLaws && opposition" class="text-md p-16 text-center font-light text-secondary-800">
              No se encontraron preguntas asociadas a leyes para los test realizados en la oposición
              {{ opposition.shortName }}
            </p>
            <div v-else class="flex w-1/2 flex-grow flex-col">
              <statistics-selector-total-law-list :laws="mostFailedLaws" />
            </div>
          </div>
        </div>
      </base-card>
    </div>
  </title-header-view>
</template>
