import QuestionList from "@/components/imported-questions/QuestionList.vue";
import QuestionDetail from "@/components/imported-questions/QuestionDetail.vue";
import QuestionPreview from "@/components/imported-questions/QuestionPreview.vue";
import QuestionSection from "@/components/imported-questions/QuestionSection.vue";
import TheHome from "@/views/TheHome.vue";
import ExamList from "@/components/imported-questions/ExamList.vue";
import ExamDetail from "@/components/imported-questions/ExamDetail.vue";
import { MODE_DUPLICATE } from "@/components/utils.js";
import PublicExaminationSummary from "@/components/public-examinations/PublicExaminationSummary.vue";
import AttemptResult from "@/components/attempt-details/AttemptResult.vue";
import InAttemptView from "@/components/attempt-details/InAttemptView.vue";
import PublicExaminationStatistics from "@/components/public-examinations/PublicExaminationStatistics.vue";
import ThePayment from "@/views/ThePayment.vue";
import ThePaymentSinglePublicExamination from "@/views/ThePaymentSinglePublicExamination.vue";
import UserListView from "@/views/staff/users/UserListView.vue";
import StaffRootView from "@/views/staff/StaffRootView.vue";
import ManageUserView from "@/views/staff/users/ManageUserView.vue";
import CreateUserView from "@/views/staff/users/CreateUserView.vue";
import InviteUsersView from "@/views/staff/users/InviteUsersView.vue";
import UsersRootView from "@/views/staff/users/UsersRootView.vue";
import PublicExaminationGlobalStatisticsView from "@/views/staff/public-examinations/PublicExaminationGlobalStatisticsView.vue";
import NewExam from "@/components/imported-questions/NewExam.vue";
import QuestionImportFromDocument from "@/components/imported-questions/QuestionImportFromDocument.vue";
import TheNotFound from "@/views/TheNotFound.vue";
import QuestionFlow from "@/components/imported-questions/QuestionFlow.vue";
import ExamPreview from "@/components/imported-questions/ExamPreview.vue";
import ExamFlow from "@/components/imported-questions/ExamFlow.vue";
import ExamSection from "@/components/imported-questions/ExamSection.vue";
import PublicExaminationUserStatistics from "@/components/statistics/PublicExaminationUserStatistics.vue";
import PublicExaminationThemesStatistics from "@/components/statistics/PublicExaminationThemesStatistics.vue";
import PublicExaminationLawsStatistics from "@/components/statistics/PublicExaminationLawsStatistics.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: TheHome,
  },
  {
    path: "/oposicion/:slug",
    name: "public-examination-summary",
    component: PublicExaminationSummary,
    children: [
      {
        path: "estadisticas",
        name: "public-examination-statistics",
        component: PublicExaminationStatistics,
      },
    ],
  },
  {
    path: "/estadisticas/:slug/temas",
    name: "public-examination-themes-statistics",
    component: PublicExaminationThemesStatistics,
  },
  {
    path: "/estadisticas/:slug/leyes",
    name: "public-examination-laws-statistics",
    component: PublicExaminationLawsStatistics,
  },
  {
    path: "/estadisticas/:slug",
    name: "public-examination-user-statistics",
    component: PublicExaminationUserStatistics,
  },
  {
    path: "/estadisticas/",
    name: "public-examination-user-statistics-no-slug",
    component: PublicExaminationUserStatistics,
  },
  {
    path: "/intento/:uuid",
    name: "in-attempt",
    component: InAttemptView,
  },
  {
    path: "/intento/:uuid/resultados",
    name: "attempt-results",
    component: AttemptResult,
  },
  {
    path: "/edicion/preguntas/",
    component: QuestionSection,
    children: [
      {
        path: "filtrado",
        name: "imported-questions",
        component: QuestionList,
      },
      {
        path: "documentacion",
        name: "question-flow",
        component: QuestionFlow,
      },
      {
        path: ":id",
        name: "question-detail",
        component: QuestionDetail,
      },
      {
        path: ":id/duplicar",
        name: "duplicate-question",
        component: QuestionDetail,
        props: { mode: MODE_DUPLICATE },
      },
      {
        path: ":id/vista-previa",
        name: "question-preview",
        component: QuestionPreview,
      },
    ],
  },
  {
    path: "/edicion/examenes/",
    component: ExamSection,
    children: [
      {
        path: "convocatorias-anteriores",
        name: "exam-list-previous-call",
        component: ExamList,
        props: { examType: "previousCall" },
      },
      {
        path: "supuesto-practico",
        name: "exam-list-case-study",
        component: ExamList,
        props: { examType: "alleged" },
      },
      {
        path: "supuesto-practico-convocatorias-anteriores",
        name: "exam-list-case-study-previous-call",
        component: ExamList,
        props: { examType: "allegedPreviousCall" },
      },
      {
        path: ":examId",
        name: "exam-detail",
        component: ExamDetail,
      },
      {
        path: "documentation",
        name: "exam-flow",
        component: ExamFlow,
      },
      {
        path: "nuevo",
        name: "new-exam",
        component: NewExam,
        props: { examType: "alleged" },
      },
      {
        path: "pregunta/:id",
        name: "exam-question-detail",
        component: QuestionDetail,
      },
      {
        path: "importar-de-documento/",
        name: "import-questions",
        component: QuestionImportFromDocument,
      },
      {
        path: ":examId/vista-previa",
        name: "exam-preview",
        component: ExamPreview,
      },
    ],
  },
  {
    path: "/pago/",
    name: "payment",
    component: ThePayment,
  },
  {
    path: "/:slug/pago",
    name: "public-examination-payment",
    component: ThePaymentSinglePublicExamination,
    props: true,
  },
  {
    path: "/staff",
    component: StaffRootView,
    children: [
      {
        path: "administrar-usuarios",
        component: UsersRootView,
        children: [
          {
            path: "",
            name: "manage-users",
            component: UserListView,
          },
          {
            path: ":id",
            name: "edit-user",
            component: ManageUserView,
          },
          {
            path: "crear",
            name: "create-user",
            component: CreateUserView,
          },
          {
            path: "invitar",
            name: "invite-user",
            component: InviteUsersView,
          },
        ],
      },
      {
        path: "oposiciones",
        name: "staff-public-examination-statistics",
        component: PublicExaminationGlobalStatisticsView,
      },
    ],
  },
  {
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#Catch-all-404-Not-found-Route
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: TheNotFound,
  },
];
