<script setup>
import BaseAnswer from "@/components/imported-questions/BaseAnswer.vue";
import { computed, ref, watch } from "vue";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  isCorrectAnswerShown: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const answered = ref(null);

const isAnswered = computed(() => {
  return answered.value != null;
});

watch(
  () => props.isCorrectAnswerShown,
  (newValue) => {
    if (newValue) {
      answered.value = props.question.correctAnswer;
    } else {
      answered.value = null;
    }
  }
);
const feedbackClass = computed(() => {
  if (isAnswered.value) {
    return " opacity-100";
  }
  return " h-0 opacity-0";
});
</script>

<template>
  <div class="overflow-hidden bg-white">
    <h3 class="text-md mx-4 mt-3 font-medium leading-6 text-secondary-600">{{ question.declaration }}</h3>
    <h5 v-if="question.image" class="mx-4 mt-2">
      <img :src="question.image" alt="Imagen de la pregunta" />
    </h5>
    <div class="mx-5 p-2">
      <base-answer
        v-for="answer in question.answers"
        :id="'answer-' + answer.id"
        name="answer"
        :key="answer.id"
        :input-value="answer.id"
        :label="answer.declaration"
        v-model="answered"
        :is-valid="answer.id === question.correctAnswer"
        class="my-2"
      />
    </div>
    <div class="mx-5 px-3 py-1 transition-opacity delay-200 ease-in-out" :class="feedbackClass">
      <h4 class="mb-2 font-medium leading-6 text-secondary-600">Explicación</h4>
      <div v-if="isAnswered" class="text-secondary-600" v-html="question.reason"></div>
    </div>
  </div>
</template>
