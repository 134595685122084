<script setup>
import { computed, onMounted, ref } from "vue";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useRoute, useRouter } from "vue-router";
import QuestionAttempt from "@/components/attempt-details/QuestionAttempt.vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseCard from "@/components/BaseCard.vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import RadioButton from "@/components/forms/RadioButton.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import BaseTh from "@/components/tables/BaseTh.vue";
import BaseTr from "@/components/tables/BaseTr.vue";
import BaseTd from "@/components/tables/BaseTd.vue";
import { ATTEMPT_TYPE_TITLES, formatDateTime } from "@/components/utils.js";
import ResultCanvas from "@/components/attempt-details/ResultCanvas.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import AttemptResultReportQuestionDialog from "@/components/attempt-details/AttemptResultReportQuestionDialog.vue";
import { ArrowPathIcon } from "@heroicons/vue/24/outline/index.js";
import ScrollToTop from "@/components/ScrollToTop.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import * as Sentry from "@sentry/browser";

const route = useRoute();
const router = useRouter();
const { sendServerError, sendNotification } = useToast();

const { callWs, record } = useWS(MainRepository.attemptDetail(route.params.uuid));
const title = computed(() => {
  let output = "";
  if (record.value != null) {
    let found = ATTEMPT_TYPE_TITLES.find((element) => element.value === record.value.type);
    if (!found) {
      Sentry.captureMessage(`Attempt of type ${record.value.type} not found in ATTEMPT_TYPE_TITLES`);
      return;
    }
    output = found.title;
    if (record.value.examTitle != null) {
      output += " - " + record.value.examTitle;
    }
  }
  return output;
});
const markUnmarkTitle = computed(() => {
  if (record.value != null) {
    if (record.value.markedAsFinished != null) {
      return !record.value.markedAsFinished ? "Marcar como finalizado" : "Desmarcar como finalizado";
    }
  }
  return "";
});

const { callWs: duplicateAttempt, record: new_attempt } = useWS(MainRepository.duplicateAttempt(route.params.uuid));
const { callWs: callMarkUnmarkAsFinished } = useWS(MainRepository.markUnmarkExamAsFinished(route.params.uuid));
const { callWs: callReportQuestion } = useWS(MainRepository.reportQuestionError());
//const { callWs: getExam, record: examRecord} = useWS(MainRepository.examDetail(record.value.examId))

onMounted(async () => {
  try {
    await callWs({});
    if (!record.value.publicExamination.userHasMembership) {
      await router.push({ name: "public-examination-payment", params: { slug: record.value.publicExamination.slug } });
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      await router.push({ name: "not-found" });
    } else {
      sendServerError(e, "ATT-DET");
    }
  }
});

function buttonRedoTestClicked() {
  if (record.value === null) {
    return;
  }
  if (isExamAttempt.value) {
    if (isExamActive.value) {
      reDoTest();
    } else {
      tryAgainDialogOpen.value = true;
    }
  } else {
    reDoTest();
  }
}

const isExamAttempt = computed(() => {
  return record.value.examTitle !== null;
});
const isExamActive = computed(() => {
  if (record.value === null) {
    return false;
  }
  return record.value.isExamActive;
});

const tryAgainButtonEnabled = ref(true);
const tryAgainDialogOpen = ref(false);

function closeTryAgainDialog() {
  tryAgainDialogOpen.value = false;
  tryAgainButtonEnabled.value = false;
}

const isTotal = ref(true);
const resultSelector = computed(() => [
  {
    label: "Todas",
    value: true,
  },
  {
    label: "Sin dudosas",
    value: false,
  },
]);
const pieSerie = computed(() => {
  const output = {
    name: "Resultados",
    total: 0,
    data: [],
  };
  if (record.value != null) {
    const results = record.value.results;
    output["total"] = isTotal.value
      ? results.nCorrectAnswers + results.nIncorrectAnswers + results.nNotAnswered
      : results.nUncertainCorrectAnswers + results.nUncertainIncorrectAnswers + results.nUncertainNotAnswered;
    output["data"].push(
      {
        value: isTotal.value ? results.nCorrectAnswers : results.nUncertainCorrectAnswers,
        percentage: isTotal.value
          ? results.nCorrectAnswers / output.total
          : results.nUncertainCorrectAnswers / output.total,
        name: "Correctas",
        itemStyle: {
          color: "#C4D600", // Cannot use tailwind classes. Color turned black. Look for a better way
        },
      },
      {
        value: isTotal.value ? results.nIncorrectAnswers : results.nUncertainIncorrectAnswers,
        percentage: isTotal.value
          ? results.nIncorrectAnswers / output.total
          : results.nUncertainIncorrectAnswers / output.total,
        name: "Incorrectas",
        itemStyle: {
          color: "#EB4949", // Cannot use tailwind classes. Color turned black. Look for a better way
        },
      },
      {
        value: isTotal.value ? results.nNotAnswered : results.nUncertainNotAnswered,
        percentage: isTotal.value ? results.nNotAnswered / output.total : results.nUncertainNotAnswered / output.total,
        name: "En blanco",
        itemStyle: {
          color: "#4B5764", // Cannot use tailwind classes. Color turned black. Look for a better way
        },
      }
    );
  }
  return output;
});

const showCorrectQuestions = ref(true);
const showIncorrectQuestions = ref(true);
const showNotAnsweredQuestions = ref(true);

const activeFilters = computed(() => {
  let activeFilters = [];
  if (showCorrectQuestions.value) {
    activeFilters.push("Correctas");
  }
  if (showIncorrectQuestions.value) {
    activeFilters.push("Incorrectas");
  }
  if (showNotAnsweredQuestions.value) {
    activeFilters.push("En blanco");
  }
  return activeFilters.join(", ");
});

const filteredQuestions = computed(() => {
  let questions = enumeratedQuestions.value;
  if (!showCorrectQuestions.value) {
    questions = questions.filter((q) => !isCorrect(q));
  }
  if (!showIncorrectQuestions.value) {
    questions = questions.filter((q) => !isIncorrect(q));
  }
  if (!showNotAnsweredQuestions.value) {
    questions = questions.filter((q) => !isEmptyQuestion(q));
  }
  return questions;
});

const enumeratedQuestions = computed(() => {
  return record.value.questions.map((q, i) => {
    q.index = i + 1;
    return q;
  });
});

function isCorrect(question) {
  return question.correctAnswer === question.selectedAnswer;
}

function isIncorrect(question) {
  return question.selectedAnswer !== null && question.correctAnswer !== question.selectedAnswer;
}

function isEmptyQuestion(question) {
  return question.selectedAnswer === null;
}

const reportedQuestion = ref(null);
const isDialogOpen = ref(false);

function sendQuestionError(question) {
  reportedQuestion.value = question;
  isDialogOpen.value = true;
}

async function reportQuestionError(data) {
  try {
    await callReportQuestion(data);
    isDialogOpen.value = false;
    sendNotification(null, "Éxito", "Se ha informado de error");
  } catch (error) {
    sendServerError(error, "ATT-REPORT");
  }
}

async function markUnmarkAsFinished() {
  await callMarkUnmarkAsFinished();
  record.value.markedAsFinished = !record.value.markedAsFinished;
  const action = record.value.markedAsFinished ? "marcado" : "desmarcado";
  sendNotification("Éxito", "Se ha " + action + " como finalizado el examen con éxito");
}

async function reDoTest() {
  let uuid = record.value?.uuid;
  if (!uuid) {
    sendServerError();
    return;
  }
  try {
    await duplicateAttempt(uuid);
    router.push({ name: "in-attempt", params: { uuid: new_attempt.value.uuid } });
  } catch (error) {
    sendServerError(error);
  }
}
</script>

<template>
  <no-header-view>
    <div v-if="record == null">Cargando...</div>
    <div v-else>
      <div class="flex justify-between">
        <h2 class="text-3xl font-semibold text-secondary-800">Resultados del test</h2>
        <button-secondary @click.prevent="router.push({ name: 'home' })" class="flex transition-opacity"
          ><span>Volver al inicio</span>
        </button-secondary>
      </div>
      <div>
        <base-card class="mt-8 p-8">
          <div class="mb-2 mt-5 flex text-3xl">
            <span>{{ title }}</span>
            <button-primary
              class="ml-0 !justify-start !px-4 !py-0 !text-base !font-light sm:ml-5"
              @click.prevent="
                router.push({
                  name: 'public-examination-user-statistics',
                  params: { slug: record.publicExamination.slug },
                })
              "
            >
              Ver estadísticas
            </button-primary>
            <div v-if="record.markedAsFinished != null">
              <button-secondary @click.prevent="markUnmarkAsFinished">{{ markUnmarkTitle }}</button-secondary>
            </div>
          </div>
          <div class="mb-2">Finalizado el {{ formatDateTime(record.modifiedAt) }}</div>
          <button-secondary
            @click="buttonRedoTestClicked"
            :disabled="!tryAgainButtonEnabled"
            class="mb-3 flex !py-1 text-sm transition-opacity"
          >
            <span>Volver a hacer este test</span>
            <arrow-path-icon class="ml-2 mt-0.5 h-4" />
          </button-secondary>
          <base-dialog :is-open="tryAgainDialogOpen">
            <template v-slot:default>
              <p>Este es un intento de un examen antiguo</p>
              <p>El examen ya no se encuentra disponible en la plataforma</p>
            </template>
            <template v-slot:buttons>
              <button-primary @click="closeTryAgainDialog">Entendido</button-primary>
            </template>
          </base-dialog>
          <div class="mb-3 flex flex-col justify-between gap-3 md:flex-row md:items-center">
            <radio-button v-model="isTotal" :options="resultSelector" id="radio-law-content-level" />
            <div class="flex flex-col gap-1">
              <p><b class="text-base font-semibold">Mostrar preguntas por tipo:</b></p>
              <div class="flex flex-row gap-2">
                <button
                  class="border-1 cursor-pointer rounded-md border border-green-solid px-4 py-2"
                  :class="{ 'bg-green': showCorrectQuestions }"
                  @click="showCorrectQuestions = !showCorrectQuestions"
                >
                  Correctas
                </button>
                <button
                  class="border-1 cursor-pointer rounded-md border border-red-300 px-4 py-2"
                  :class="{ 'bg-red-100': showIncorrectQuestions }"
                  @click="showIncorrectQuestions = !showIncorrectQuestions"
                >
                  Incorrectas
                </button>
                <button
                  class="border-1 cursor-pointer rounded-md border border-blue-solid px-4 py-2"
                  :class="{ 'bg-blue': showNotAnsweredQuestions }"
                  @click="showNotAnsweredQuestions = !showNotAnsweredQuestions"
                >
                  En blanco
                </button>
              </div>
              <small class="text-sm font-light">
                Pulsa en cada una de las fichas para mostrar/ocultar preguntas de ese tipo
              </small>
            </div>
          </div>

          <fade-transition>
            <div
              :key="isTotal ? 'total' : 'uncertain'"
              class="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
            >
              <div
                v-for="data in pieSerie.data"
                :key="data.name"
                class="flex w-full items-center rounded-lg bg-background p-4"
              >
                <result-canvas
                  :percentage-color="data.itemStyle.color"
                  :value="data.value"
                  :percentage="data.percentage"
                  :show-percentage="false"
                />
                <div class="ml-4 flex flex-col">
                  <div class="text-lg font-medium">{{ data.name }}</div>
                  <div class="text-base font-light">
                    {{ data.value }} respuestas <span class="lowercase"> {{ data.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </fade-transition>
          <div class="overflow-hidden rounded-lg border border-background">
            <div class="relative w-full overflow-x-auto">
              <table class="w-full table-auto">
                <base-tr>
                  <base-th class="!bg-secondary-150"></base-th>
                  <base-th class="!bg-secondary-150 !text-lg !font-semibold !text-secondary-800">Puntuación</base-th>
                  <base-th class="!bg-secondary-150 !text-lg !font-semibold !text-secondary-800">Correctas</base-th>
                  <base-th class="!bg-secondary-150 !text-lg !font-semibold !text-secondary-800">Incorrectas</base-th>
                  <base-th class="!bg-secondary-150 !text-lg !font-semibold !text-secondary-800">En blanco</base-th>
                </base-tr>
                <base-tr class="border-b border-b-background">
                  <base-td class="!text-lg !font-semibold">Todas</base-td>
                  <base-td class="!text-lg !font-light">{{ record.results.score }}</base-td>
                  <base-td class="!text-lg !font-light">{{ record.results.nCorrectAnswers }}</base-td>
                  <base-td class="!text-lg !font-light">{{ record.results.nIncorrectAnswers }}</base-td>
                  <base-td class="!text-lg !font-light">{{ record.results.nNotAnswered }}</base-td>
                </base-tr>
                <base-tr class="border-b border-b-background">
                  <base-td class="!bg-background !text-lg !font-semibold">Sin incluir dudosas</base-td>
                  <base-td class="!bg-background !text-lg font-light">{{ record.results.scoreUncertain }}</base-td>
                  <base-td class="!bg-background !text-lg font-light">
                    {{ record.results.nUncertainCorrectAnswers }}
                  </base-td>
                  <base-td class="!bg-background !text-lg font-light">
                    {{ record.results.nUncertainIncorrectAnswers }}
                  </base-td>
                  <base-td class="!bg-background !text-lg font-light">
                    {{ record.results.nUncertainNotAnswered }}
                  </base-td>
                </base-tr>
              </table>
            </div>
          </div>
        </base-card>
      </div>
      <div class="my-10 text-lg font-light text-secondary-800">
        <base-card v-if="record.statement != null" class="p-8">
          <div v-html="record.statement"></div>
        </base-card>
      </div>
      <div>
        <div class="sticky top-0 z-50 mb-5">
          <base-card v-if="filteredQuestions.length !== record.questions.length" class="px-8 py-5 drop-shadow-lg">
            <div v-if="filteredQuestions.length !== 0">
              Mostrando preguntas: {{ activeFilters }}
              <small class="ml-5">
                (mostrando {{ filteredQuestions.length }} de {{ record.questions.length }} preguntas)
              </small>
            </div>
            <div v-else>
              <div v-if="activeFilters !== ''">No existen preguntas del tipo {{ activeFilters }}.</div>
              <div v-else>No hay seleccionado ningún tipo de pregunta para mostrar.</div>
              <br />
              Haz click en algún tipo de pregunta en la sección <b>Mostrar preguntas por tipo:</b> para mostrar alguna
              pregunta.
            </div>
          </base-card>
        </div>
        <question-attempt
          v-for="(question, index) in filteredQuestions"
          :key="question.index"
          :question="question"
          :index="question.index"
          :id="'id-question-' + question.id"
          :name="'question-' + question.id"
          :show-result="true"
          :show-feedback="true"
          :disabled="true"
          class="z-0"
          @error="sendQuestionError(question)"
        />
      </div>
      <attempt-result-report-question-dialog
        :question="reportedQuestion"
        :is-open="isDialogOpen"
        @close-dialog="isDialogOpen = false"
        @accept="reportQuestionError"
      />
      <scroll-to-top class="bottom-10" />
    </div>
  </no-header-view>
</template>
